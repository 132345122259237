@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;

[data-sparta-container] .#{$moduleNameSpace} {
  .spa-sticky {
    background-color: $color-gray-s05;
    visibility: hidden;
    padding: $spacing-4 0;

    &.active {
      visibility: visible;
    }

    .get-app-text {
      font-size: 14px;
      margin-right: $spacing-20;
      margin-top: $spacing-10;
    }

    > .show-for-small-only {
      .small-centered {
        text-align: center;
      }

      .small-right {
        text-align: right;
      }
    }

    > .show-for-medium-up {
      text-align: right;
    }
  }

  a {
    @media #{$medium-up} {
      margin-left: $spacing-4;
    }

    &.spa-btn {
      line-height: 1;
    }
  }
}
